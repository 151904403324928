import http from "@/http";

let modulePath = "company/Termination";

const post = function (path, data, config = {}) {
  return http.post(`${modulePath}/${path}`, data, config);
};

const get = function (path, config = {}) {
  return http.get(`${modulePath}/${path}`, config);
};

export default {
  getResignStaff(jwt, enqParamsObj) {
    return get("resignStaff", {
      headers: {
        Authorization: "Bearer " + jwt
      },
      params: enqParamsObj
    });
  },
  getValidStaffCount(jwt, searchStaffObj) {
    return get("staffCount", {
      headers: {
        Authorization: "Bearer " + jwt
      },
      params: generateParamsObj(searchStaffObj)
    });
  },
  getValidStaff(jwt, searchStaffObj) {
    return get("staffList", {
      headers: {
        Authorization: "Bearer " + jwt
      },
      params: generateParamsObj(searchStaffObj)
    });
  },
  raiseResign(jwt, ItemKeys) {
    let dataObj = {
      keys: ItemKeys
    };
    return post("raiseResign", dataObj, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt
      }
    });
  },
  postResignRevoke(jwt, id) {
    let dataObj = {
      id: id
    };
    return post("revokeResign", dataObj, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt
      }
    });
  }
};

function generateParamsObj(searchStaffObj) {
  return {
    staffName: searchStaffObj.value.name,
    identityNum: searchStaffObj.value.identity,
    validSortProp: searchStaffObj.sorting.prop,
    validSortorder: searchStaffObj.sorting.order,
    offsetTotal: searchStaffObj.filter.offset,
    nextFetchTotal: searchStaffObj.filter.nextFetch,
    timestamp: searchStaffObj.filter.timestamp,
  }
}
