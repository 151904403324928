<template>
  <div class="company-cardCancellation">
    <div class="title">
      <h3>{{ $t(`company.menu.cardCancellation`) }}</h3>
      <el-divider />
    </div>

    <div class="table">
      <el-collapse ref="filter" v-model="activeNames" @change="handleCollapseChange">
        <el-collapse-item name="1">
          <template #title>
            <el-icon class="icon">
              <i class="bi bi-funnel-fill" />
            </el-icon>
            <h3>{{ $t("company.enquiry.filter") }}</h3>
          </template>
          <div class="search-filter">
            <el-form :rules="rules" ref="searchResignObj">
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item :label="th('nameShort')">
                    <el-input v-model="searchResignObj.name" clearable />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item :label="th('searchIdentityNum')">
                    <el-input :maxlength="4" v-model="searchResignObj.identityNumber" clearable />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item :label="th('status')">
                    <el-select v-model="searchResignObj.status" :placeholder="$i18n.t('common.select.placeholder')" clearable>
                      <el-option v-for="(val, index) in tbm('resignStatus')" :key="index" :value="index" :label="val" />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item :label="th('applied.start')">
                    <el-date-picker v-model="searchResignObj.createDateStart" type="date" :placeholder="$i18n.t('common.datepicker.placeholder')"
                      :disabled-date="disabledDate" />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item :label="th('applied.end')" v-if="searchResignObj.createDateStart">
                    <el-date-picker v-model="searchResignObj.createDateEnd" type="date" :disabled-date="disabledEndDate"
                      :placeholder="$i18n.t('common.datepicker.placeholder')" />
                  </el-form-item>
                </el-col>
              </el-row>
              <div class="table-footer right">
                <el-button style="margin-right: 20px" type="danger" plain @click="clearFilter">
                  <el-icon class="el-icon--left">
                    <i class="bi bi-eraser-fill"></i>
                  </el-icon>
                  <span>{{ $t("common.button.clearFilter") }}</span>
                </el-button>
                <el-button type="primary" @click="search">
                  <el-icon>
                    <i class="bi bi-search"></i>
                  </el-icon>
                  <span>{{ $t("common.button.search") }}</span>
                </el-button>
              </div>
            </el-form>
          </div>
        </el-collapse-item>
      </el-collapse>
      <el-divider />

      <el-row class="toolbar centerItem">
        <el-col :span="12" v-if="!table.loading">
          {{
            table.list.length > 0
            ? i18nPluralizationL(
              "company.enquiry.recordFound",
              table.list.length
            )
            : this.$i18n.t("company.enquiry.noData")
          }}
        </el-col>

        <el-col :span="12" class="right">
          <el-button plain @click="openDrawer" v-if="!table.loading">
            <el-icon class="el-icon--left">
              <i class="bi bi-file-earmark-arrow-up-fill" />
            </el-icon>
            <span>{{ $t("common.button.cancellation") }}</span>
          </el-button>
        </el-col>
      </el-row>

      <el-table :data="table.list.slice((table.page.currentPage - 1) * table.page.pagesize, table.page.currentPage * table.page.pagesize)
        " @selection-change="selectionChange" v-loading="table.loading"
        :empty-text="this.$i18n.t('company.enquiry.noData')">
        <el-table-column width="15"></el-table-column>
        <el-table-column :label="th('name')" prop="staffName" min-width="260" sortable></el-table-column>
        <el-table-column :label="th('identityNum')" prop="identityNumber" min-width="250" sortable>
          <template #default="scope">
            {{ scope.row.identityNumber }}
          </template>
        </el-table-column>
        <el-table-column :label="th('requestDate')" prop="applyDate" min-width="150" sortable>
          <template #default="scope">
            {{ formatDT(scope.row.applyDate, "YYYY-MM-DD") }}
          </template>
        </el-table-column>
        <el-table-column :label="th('revokeDate')" prop="revokeDate" min-width="150" sortable>
          <template #default="scope">
            {{
              scope.row.revokeDate
              ? formatDT(scope.row.revokeDate, "YYYY-MM-DD")
              : ""
            }}
          </template>
        </el-table-column>
        <el-table-column :label="th('status')" prop="status" min-width="200" sortable>
          <template #default="scope">
            {{
              this.$i18n.t(`company.cancellation.status.${scope.row.status}`)
            }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" min-width="130" min-height="57">
          <template #default="scope">
            <el-button size="small" plain @click="rowRevoke($event, scope.$index, scope.row)"
              v-if="scope.row.status == 1">
              <el-icon>
                <RefreshLeft />
              </el-icon>
              <span>{{ $t("common.button.revoke") }}</span>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-config-provider :locale="getLang">
          <el-pagination class="pagination" :currentPage="table.page.currentPage" :page-size="table.page.pagesize" :page-sizes="[25, 50, 100]"
            layout="sizes, prev, pager, next" :total="table.list.length" @size-change="handleSizeChange"
            @current-change="handlePageChange" />
        </el-config-provider>
      </div>
    </div>

    <el-drawer v-model="drawer.display" :direction="drawer.direction" :size="drawerWidth" :title="th('cardCancellation')"
      :before-close="onCloseDrawer" modal-class="drawerClass">
      <template #>
        <div>
          <el-form>
            <el-row :gutter="20" align="bottom">
              <el-col :xs="9" :sm="8" :md="9" :lg="9" :xl="9">
                <el-form-item :label="th('nameShort')">
                  <el-input v-model="searchStaffObj.value.name" clearable @keydown.enter="searchForStaffList" />
                </el-form-item>
              </el-col>
              <el-col :xs="9" :sm="8" :md="9" :lg="9" :xl="9">
                <el-form-item :label="th('searchIdentityNum')">
                  <el-input v-model="searchStaffObj.value.identity" :maxlength="4" clearable @keydown.enter="searchForStaffList" />
                </el-form-item>
              </el-col>
              <el-col :xs="3" :sm="4" :md="3" :lg="3" :xl="3">
                <el-button type="danger" plain @click="clearValidFilter">
                  <el-icon class="el-icon--left">
                    <i class="bi bi-eraser-fill"></i>
                  </el-icon>
                  <span>{{ $t("common.button.clear") }}</span>
                </el-button>
              </el-col>
              <el-col :xs="4" :sm="4" :md="3" :lg="3" :xl="3">
                <el-button type="primary" @click="searchForStaffList">
                  <el-icon>
                    <i class="bi bi-search" />
                  </el-icon>
                  <span>{{ $t("common.button.search") }}</span>
                </el-button>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <el-divider />
        <el-row justify="space-between">
          <el-col :span="11">
            <div>
              <span class="drawerTableHeader">{{
                $t("company.cancellation.validStaffList")
              }}</span>
            </div>
          </el-col>
          <el-col :span="1"></el-col>
          <el-col :span="11">
            <div class="toBeList">
              <span class="drawerTableHeader">{{
                $t(`company.cancellation.toBeCancelStaffList`)
              }}</span>
            </div>
          </el-col>
        </el-row>
        <el-row justify="space-between">
          <el-col :span="11">
            <div v-if="staffSearch.inProgress">
              <el-tag v-if="isObjectEmpty(searchStaffObjLabel)? false :Object.values(searchStaffObjLabel.value).every(x => !x)" class="mx-1">
                {{ $t("company.table.headers.allRecords") }}
              </el-tag>
              <div class="search-staff-labels" v-else>
                <div class="search-staff-labels" v-for="(value, key) in searchStaffObjLabel.value" v-bind:key="key">
                  <el-tag v-if="value"  class="mx-1">
                    {{ $t(`company.cancellation.searchStaffObj.${key}`) }}: {{value}} 
                  </el-tag>
                </div>
              </div>
              <div style="display:inline" v-if="showResultCount">
                {{
                  (this.staffTable.total > 0)
                  ? i18nPluralizationL(
                    "company.enquiry.recordFound",
                    this.staffTable.total
                  )
                  : this.$i18n.t("company.enquiry.noData")
                }}
              </div>
            </div>
          </el-col>
          <el-col :span="1"></el-col>
          <el-col :span="11">
            <div class="to-be-list-selected" v-if="this.toBeTerminateStaffList.length >0">
              <div class="to-be-selected-label" >
                {{ 
                  i18nPluralizationL(
                    "company.cancellation.recordSelected",
                    this.toBeTerminateStaffList.length
                  )
                }}
              </div>
              <el-button class="float-right" size="small"
                @click="resetSelectedTerminateList">
                <span>{{ $t("common.button.reset") }}</span>
              </el-button>
            </div>
          </el-col>
        </el-row>
        <el-row class="drawerTable" justify="space-between">
          <el-col :span="11">
            <el-table :data="staffTableList" v-loading="staffTable.loading"
              :empty-text="this.$i18n.t('company.enquiry.noData')" height="85%" class="drawerResultTable"
              @selection-change="staffTableSelectionChange" :cell-style="cellStyle" @sort-change="staffTableSortChange" ref="staffTable">
              <el-table-column type="selection" width="25" />
              <el-table-column :label="th('nameShort')" prop="staffName" min-width="180" :sortable="staffSearch.inProgress ? 'custom' : false " />
              <el-table-column :label="th('identityNum')" prop="identityNumber" min-width="140" :sortable="staffSearch.inProgress ? 'custom' : false " />
            </el-table>

            <el-config-provider :locale="getLang">
              <el-pagination class="pagination" v-if="staffTable.list.length > 0" :page-size="50" layout="prev, slot,  next" :total="staffTableRecordTotal" :disabled="staffTable.loading"
                @prev-click="handleDrawerPageChange('prev')"
                @next-click="handleDrawerPageChange('next')">
                <template #default>
                  <div class="staff-page-num">
                    {{ staffTable.page }}
                  </div>
                </template>
              </el-pagination>
            </el-config-provider>
          </el-col>

          <el-col :span="1" class="centerButton">
            <el-button plain @click="addToTmpTable">
              <el-icon :size="10">
                <ArrowRightBold />
              </el-icon>
            </el-button>
            <div />
            <el-button plain @click="removeFromTmpTable">
              <el-icon :size="10">
                <ArrowLeftBold />
              </el-icon>
            </el-button>
          </el-col>

          <el-col :span="11">
            <el-table :data="toBeTerminateStaffList" @selection-change="tmpTableSelectionChange"
              v-loading="tmpTable.loading" :empty-text="this.$i18n.t('company.enquiry.noData')" height="85%"
              class="drawerResultTable">
              <el-table-column type="selection" width="25" />
              <el-table-column :label="th('nameShort')" prop="staffName" min-width="180" sortable />
              <el-table-column :label="th('identityNum')" prop="identityNumber" min-width="140" sortable />
            </el-table>
          </el-col>
        </el-row>

        <el-row justify="end">
          <el-button type="primary" @click="toRaiseResign" :disabled="toBeTerminateStaffList.length < 1">
            <span class="smallButton">{{ $t("common.button.submit") }}</span>
          </el-button>
        </el-row>
      </template>
    </el-drawer>
  </div>
</template>

<style scoped>
:deep(.mx-1) {
  margin: 4px;
  font-size: 14px;
}

.drawerClass {
  overflow: auto;
}

:deep(.left) {
  flex-wrap: wrap;
  display: flex;
  align-content: center;
}

@media screen and (min-width: 1200px) {
  :deep(.el-drawer) {
    width: 70% !important;
  }
}

@media screen and (max-width: 1199px) {
  :deep(.el-drawer) {
    width: 100% !important;
  }
}

.toBeList {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
}

.drawerTableHeader {
  font-size: 1.17em;
  font-weight: bold;
  /* padding-bottom: 10px; */
  display: block;
  line-height: 32px;
  height: 32px;
}

.to-be-list-selected {
  line-height: 40px;
  height: 40px;
}

.drawerTable {
  height: 65vh;
}

.searchValidButton {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  padding: 0;
  align-items: flex-end;
}

.drawerResultTable {
  border: 1px lightgray solid;
}

:deep(.el-drawer__header) {
  font-size: 1.17em;
  font-weight: bold;
  margin-bottom: 0;
  background-color: #f3f3f3;
}

:deep(.el-drawer__body) {
  overflow-y: auto;
  background-color: #f3f3f3;
}

.search-staff-labels {
  display: inline;
}

.centerItem {
  align-items: center;
}

.pagination {
  text-align: center;
  margin-top: 10px;
}

.company-terminal:deep(.add-staff-form) .el-form-item {
  display: block;
  margin: 0;
}

.el-form-item {
  display: block;
  margin-right: 8px;
  margin-bottom: 0px;
}

.icon {
  margin-right: 10px;
  font-size: 1em;
}

.toolbar,
.title {
  margin: 10px 20px;
}

.toolbar .right,
.table-footer.right {
  text-align: right;
}

.table {
  margin: 10px 20px;
  height: 100%;
}

.smallButton {
  width: 40px;
  display: inline-table;
}

.company-cardCancellation:deep(.el-table) {
  width: 100%;
}

.company-cardCancellation:deep(.el-table__append-wrapper) {
  text-align: right;
  padding: 10px;
}

.el-table:deep(.cell) {
  line-height: 32px;
  word-break: break-word;
}

.table:deep(.el-collapse) {
  border: none;
}

.table:deep(.el-collapse-item__header) {
  background-color: white;
  border-bottom: 1px solid var(--el-border-color-base);
}

.table:deep(.el-collapse-item__header.is-active) {
  background-color: #b44357;
}

.search-filter {
  padding: 0px 20px;
}

.to-be-selected-label {
  display: inline;
  padding-left: 5px;
}

.centerButton {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-content: center;
  flex-direction: column;
  padding-top: 12px;
}

.search-filter .el-form-item {
  display: block;
}

.company-cardCancellation:deep(.el-icon.el-collapse-item__arrow) {
  transform: none;
}

.company-cardCancellation:deep(.el-icon.el-collapse-item__arrow.is-active) {
  transform: rotate(90deg);
}

.company-cardCancellation:deep(.staff-dialog .el-dialog__body) {
  padding: 10px 20px;
}

.company-cardCancellation:deep(.staff-dialog-header) {
  margin: 5px 0px 0px 0px;
  font-weight: bold;
}

.company-cardCancellation:deep(.add-staff-form .el-form-item) {
  display: block;
  margin: 0;
}

.pagination:deep(.el-pager > li),
.pagination:deep(.btn-prev),
.pagination:deep(.btn-next) {
  margin: 0px 1px;
}

:deep(.el-form-item__label) {
  white-space: nowrap;
}

.staff-page-num {
  height: 28px;
  padding: 0px 5px;
  margin: 0px 5px;
  line-height: 28px;
  min-width: 30px;
  display: inline-block;
  background-color: #FFFFFF;
  font-weight: normal;
  color: #606266;
  font-size: 14px;
}
.drawerResultTable:deep(.el-loading-mask) {
  background-color: #FFFFFF;
}
.float-right {
  float: right;
}
</style>

<script>
import moment from "moment";
import cancellation from "../../http/apis/company/cancellation.js";
import {
  handleCompanyException,
  i18nPluralization,
} from "../../libs/common.js";
import { ConfirmDialog, SuccessMessage } from "../../plugins/message.js";
import TableViewVue from "./TableView.vue";
import useVuelidate from "@vuelidate/core";
import eng from "element-plus/lib/locale/lang/en";
import chi from "element-plus/lib/locale/lang/zh-tw";
import constants from "@/libs/constants";
import * as common from "@/libs/common";

export default {
  name: "CardCancellation",
  extends: TableViewVue,
  setup() {
    return {
      v$: useVuelidate({
        $lazy: true,
        $autoDirty: false,
      }),
    };
  },
  data() {
    return {
      drawer: {
        direction: "rtl",
        display: false
      },
      
      // drawerWidth: "70%",
      activeNames: "0",
      table: {
        list: [],
        selectionList: [],
        loading: false,
        page: {
          pagesize: 25,
          currentPage: 1,
        }
      },
      staffTable: this.getDefaultStaffTableObj(),
      tmpTable: {
        list: [],
        selectionList: [],
      },
      searchStaffObj: this.getDefaultSearchStaffObj(),
      searchStaffObjLabel: {},
      staffSearch: {
        inProgress: false,
        result: {
          total: false,
          list: false
        }
      }
    };
  },
  methods: {
    resetSelectedTerminateList() {
      this.$store.commit("clearToBeTerminateStaffList");
      // this.staffTable.selectedCount = 0;
    },
    resetValidList() {
      this.searchResignObj = this.getDefaultSearchStaffObj();
      this.searchStaffObjLabel = {};
      this.staffTable = this.getDefaultStaffTableObj();
      this.staffSearch = {
        isExtend: false,
        inProgress: false,
        result: {
          total: false,
          list: false
        }
      }
    },
    disabledDate(date) {
      const now = new Date();
      const oneYearAgo = new Date(
        now.getFullYear() - 1,
        now.getMonth(),
        now.getDate() + 1
      );
      return date.getTime() > now || date.getTime() < oneYearAgo;
    },
    disabledEndDate(date) {
      const now = new Date();
      if (this.searchResignObj.createDateStart) {
        return (
          date.getTime() > now ||
          date.getTime() < this.searchResignObj.createDateStart
        );
      } else {
        return this.disabledDate(date);
      }
    },
    // Table
    cellStyle() {
      return "padding: 8px 0";
    },
    handleSizeChange(val) {
      this.table.page.pagesize = val;
    },
    handlePageChange(currentPage) {
      this.table.page.currentPage = currentPage;
    },
    handleDrawerPageChange(action) {
      let page;
      if (action == 'next') page = this.staffTable.page + 1;
      if (action == 'prev' && this.staffTable.page > 1) page = this.staffTable.page - 1;
      this.scrollStaffTableToTop();
      let terminateStaffKeys = this.toBeTerminateStaffList.map((obj) => obj.enKey);
      if (this.staffTable.list.filter((obj) => terminateStaffKeys.indexOf(obj.enKey) == -1).length - ((page) * 50) <=50 ) {
        this.extendStaffList(page);
      } else {
        this.staffTable.page = page;
      }
    },
    scrollStaffTableToTop() {
      document.querySelector(".drawerResultTable .el-table__body-wrapper").scrollTop = 0;
    },
    staffTableSelectionChange(selection) {
      this.staffTable.selectionList = selection;
    },
    staffTableSortChange(event) {
      if (event.prop == "staffName" && event.order == "ascending") {
        this.searchStaffObjLabel.sorting.prop = null;
        this.searchStaffObjLabel.sorting.order = null;
      } else {
        this.searchStaffObjLabel.sorting.prop = event.prop;
        this.searchStaffObjLabel.sorting.order = event.order;
      }

      this.searchStaffObj.filter.offset = 0;
      this.scrollStaffTableToTop();
      this.loadValidStaffData();
    },
    tmpTableSelectionChange(selection) {
      this.tmpTable.selectionList = selection;
    },
    addToTmpTable() {
      let selectedRecords = this.staffTable.selectionList;
      this.$store.commit(
        "updateToBeTerminateStaffList",
        this.toBeTerminateStaffList.concat(selectedRecords)
      );
      // this.staffTable.selectedCount += selectedRecords.length

      let terminateStaffKeys = this.toBeTerminateStaffList.map((obj) => obj.enKey);

      if(this.staffTable.list.filter(
          (record) => terminateStaffKeys.indexOf(record.enKey) == -1
        ).length <= 50) {
        this.extendStaffList();
      }
    },

    removeFromTmpTable() {
      // let listKeys = this.staffTable.list.map((obj) => obj.enKey);
      let selectedKeys = this.tmpTable.selectionList.map((obj) => obj.enKey);
      // this.staffTable.selectedCount -= this.tmpTable.selectionList.filter((record) => listKeys.indexOf(record.enKey) > -1).length;
      // console.log(this.staffTable.selectedCount)
      this.$store.commit(
        "updateToBeTerminateStaffList",
        this.toBeTerminateStaffList.filter(
          (record) => selectedKeys.indexOf(record.enKey) == -1
        )
      );
    },
    rowRevoke(event, index, row) {
      this.targetBlur(event);
      let dialogMsgObj = this.$i18n.tm("company.dialog.revokeConfirmation");
      ConfirmDialog(dialogMsgObj.title, dialogMsgObj.message, () => {
        this.tableLoading(true);
        cancellation
          .postResignRevoke(this.$store.getters.companyJWT, row.id)
          .then(() => {
            this.handleSuccessAction("revoke");
          })
          .catch((err) => {
            handleCompanyException(err);
          })
          .finally(() => {
            this.loadData();
            this.tableLoading(false);
          });
      });
    },
    closeDrawerAndCleanState() {
      this.drawer.display = false;
      if (!this.staffTable.loading) {
        this.resetValidList();
      }
    },
    onCloseDrawer() {
      if (this.toBeTerminateStaffList.length > 0) {
        let dialogMsgObj = this.$i18n.tm("company.dialog.clearConfirmation");
        ConfirmDialog(
          dialogMsgObj.title,
          dialogMsgObj.message,
          () => {},
          () => {
            this.resetSelectedTerminateList();
          },
          () => {
            this.closeDrawerAndCleanState();
          },
          {
            confirmButtonText: this.$i18n.t("common.button.keep"),
            cancelButtonText: this.$i18n.t("common.button.clear"),
            showClose: false
          }
        );
        
      } else {
        this.closeDrawerAndCleanState();
      }
    },
    toRaiseResign() {
      let resignList = this.toBeTerminateStaffList;
      let numberOfRecord = Object.keys(this.toBeTerminateStaffList).length;
      let dialogMsgObj = this.$i18n.tm("company.dialog.raiseCardCancellation");
      let dialogMsgObjMessage = i18nPluralization(
        this.$i18n.t("company.dialog.raiseCardCancellation.message"),
        { num: numberOfRecord }
      );
      ConfirmDialog(
        dialogMsgObj.title,
        dialogMsgObjMessage,
        () => {
          this.tableLoading(true);
          cancellation
            .raiseResign(
              this.$store.getters.companyJWT,
              resignList.map(obj=>obj.enKey)
            )
            .then(() => {
              this.handleSuccessAction("submit");
              this.loadData();
              this.resetSelectedTerminateList();
              this.closeDrawerAndCleanState();
            })
            .catch((err) => {
              handleCompanyException(err, false);
            });
        },
        () => {
          return null;
        }
      );
    },
    tableLoading(flag) {
      this.table.loading = flag;
    },
    staffTableLoading(flag) {
      this.staffTable.loading = flag;
    },
    formatDT(source, format) {
      return moment(source).format(format);
    },
    search() {
      let startDate = this.searchResignObj.createDateStart;
      let endDate = this.searchResignObj.createDateEnd;
      if(startDate) {
        this.searchResignObj.createDateStart = new Date(
          moment(startDate).format("YYYY-MM-DD").toString()
        );
      }
      this.searchResignObj.createDateEnd = startDate ? moment(endDate?? startDate).format("YYYY-MM-DD").toString() : null;
      this.$store.commit("updateSearchResignObj", this.searchResignObj);
      this.activeNames = null;
      this.loadData();
    },
    searchForStaffList() {
      // filter empty search value
      this.staffSearch.inProgress = true;
      this.searchStaffObj.filter = {
        offset: 0,
        nextFetch: 100,
        timestamp: moment().format("YYYY-MM-DD HH:mm:ss")
      };
      this.searchStaffObj.sorting = this.getDefaultSearchSortObj();
      this.searchStaffObjLabel = JSON.parse(JSON.stringify(this.searchStaffObj));
      this.$refs.staffTable.clearSort();
      
      this.loadValidStaffData();
    },
    clearFilter() {
      this.$store.commit("clearSearchResignObj");
    },
    getDefaultStaffTableObj() {
      return {
        list: [],
        selectionList: [],
        loading: false,
        page: 1,
        // selectedCount: 0,
        filterCount: 0,
        total: null
      }
    },
    getDefaultSearchStaffObj() {
      return {
        value: {
          name: null,
          identity: null
        },
        sorting: this.getDefaultSearchSortObj(),
        filter: this.getDefaultSearchFilterObj()
      }
    },
    getDefaultSearchSortObj() {
      return {
        prop: null,
        order: null
      }
    },
    getDefaultSearchFilterObj() {
      return {
        offset: 0,
        nextFetch: 100,
        timestamp: null
      }
    },
    clearValidFilter() {
      this.searchStaffObj = this.getDefaultSearchStaffObj();
    },
    // common
    targetBlur(event) {
      event.currentTarget.blur();
    },
    dialogClose(dialog) {
      dialog.display = false;
    },
    // sleep(ms) {
    //   return new Promise((resolve) => setTimeout(resolve, ms));
    // },
    loadData() {
      this.tableLoading(true);
      let jwt = this.$store.getters.companyJWT;
      let searchResignObj = this.$store.getters.searchResignObj;
      cancellation
        .getResignStaff(jwt, searchResignObj)
        .then((res) => {
          if (res.status == 200) this.table.list = res.data;
        })
        .catch((err) => {
          handleCompanyException(err);
        })
        .finally(() => {
          this.tableLoading(false);
        });
    },
    loadValidStaffData() {
      this.staffSearch.result = {
        total: false,
        list: false
      };
      this.staffSearch.isExtend = false;
      this.staffTable = this.getDefaultStaffTableObj();
      this.staffTableLoading(true);
      let jwt = this.$store.getters.companyJWT;
      let promises = [
        cancellation.getValidStaffCount(jwt, this.searchStaffObjLabel),
        cancellation.getValidStaff(jwt, this.searchStaffObjLabel)
      ];

      Promise.allSettled(promises).then((results) => {
        // results.forEach((result) => console.log(result))
        if (results.some((obj) => obj.status == "rejected")) {
          this.handleGetValidStaffListFailure(results.find((obj) => obj.status == "rejected"));
          return;
        }
        results.forEach((result)=>this.handleGetValidStaffListResult(result));
      });
    },
    handleGetValidStaffListFailure(err) {
      handleCompanyException(err?.reason);
      this.resetValidList();
      this.staffTableLoading(false);
    },
    handleGetValidStaffListResult(result) {
      let action;
      switch (typeof result.value.data) {
        case "object":
          action = Array.isArray(result.value.data) ? "list" : null;
          break;
        case "number":
          action = "total"
          break;
      }
      if (!action) {
        handleCompanyException();
        return;
      }
      this.staffTable[action] = result.value.data;
      this.staffSearch.result[action] = true;
    },
    extendStaffList(pageNum = null) {
      // console.log("extend")
      let jwt = this.$store.getters.companyJWT;
      let searchObj = JSON.parse(JSON.stringify(this.searchStaffObjLabel))
      searchObj.filter.offset = this.staffTable.list.length;
      searchObj.filter.nextFetch = 200;
      if (searchObj.filter.offset < this.staffTable.total?? 0) {
        this.staffTableLoading(true);
        this.staffSearch.result.list = false;
        this.staffSearch.isExtend = true;
        cancellation
          .getValidStaff(jwt, searchObj)
          .then((res) => {
            if (res.status == 200) {
              this.staffSearch.result.list = true;
              this.staffTable.list = this.staffTable.list.concat(res.data);
              if (pageNum !== null) {
                this.staffTable.page = pageNum;
              }
            }
          })
          .catch((err) => handleCompanyException(err))
          .finally(()=> this.staffTableLoading(false));
      } else {
        if (pageNum !== null) {
          this.staffTable.page = pageNum;
        }
      }
    },
    openDrawer() {
      this.drawer.display = true;
      // if (this.staffSearch.inProgress) {
      //   this.loadValidStaffData();
      // }
    },
    handleCollapseChange(val) {
      this.activeNames = (val.length - 1).toString();
    },
    handleSuccessAction(action = "default") {
      SuccessMessage(this.$i18n.t(`company.message.success.${action}`));
    },
    i18nPluralizationL(path, numberOfRecord) {
      return i18nPluralization(
        this.$i18n.t(path),
        { num: numberOfRecord },
        { num: constants.unit.entry }
      );
    },
    isInvalid(invalid, extraClass = "") {
      return common.isInvalid(invalid, extraClass);
    },
    isObjectEmpty(obj){
      return (
        obj &&
        Object.keys(obj).length === 0 &&
        obj.constructor === Object
      );
    },
    refreshValidStaffFilterCount() {
      let searchStaffObjLabel = this.searchStaffObjLabel;
      if (this.isObjectEmpty(searchStaffObjLabel)) return;
      this.staffTable.filterCount = this.toBeTerminateStaffList.filter((obj) => {
        return (searchStaffObjLabel.value.name ? obj.staffName.indexOf(searchStaffObjLabel.value.name.toUpperCase()) > -1 : true) && (searchStaffObjLabel.value.identity ? obj.identityNumber.indexOf(searchStaffObjLabel.value.identity.toUpperCase()) > -1 : true)
      }).length;
    }
  },
  computed: {
    searchResignObj() {
      return this.$store.getters.searchResignObj;
    },
    getLang() {
      return this.$i18n.locale == "ch" ? chi : eng;
    },
    toBeTerminateStaffList() {
      // console.log(this.$store.getters.getToBeTerminateStaffList)
      // let terminateList = this.$store.getters.getToBeTerminateStaffList;
      // let searchStaffObjLabel = this.searchStaffObjLabel;
      // this.staffTable.filterCount = terminateList.filter((obj) => {
      //   return (searchStaffObjLabel.value.name ? obj.staffName.indexOf(searchStaffObjLabel.value.name) > -1 : true) && (searchStaffObjLabel.value.identity ? obj.identityNumber.indexOf(searchStaffObjLabel.value.identity) > -1 : true)
      // }).length;
      // return terminateList;
      return this.$store.getters.getToBeTerminateStaffList;
    },
    staffTableList() {
      let resignList = this.toBeTerminateStaffList.map(obj => obj.enKey);
      let list = this.staffTable.list
        .filter((item) => resignList.indexOf(item.enKey) == -1)
        .slice((this.staffTable.page - 1) * 50, this.staffTable.page * 50);
      if(Object.values(this.staffSearch.result).every(flag => flag == true)) {
        this.staffTableLoading(false);
      };
      this.refreshValidStaffFilterCount();
      // console.log(this.staffTable.list.length, this.staffTable.filterCount)
      return list;
    },
    showResultCount() {
      return this.staffTable.total !== null && this.staffSearch.isExtend ? this.staffSearch.result.total : Object.values(this.staffSearch.result).every(x => !!x)
    },
    staffTableRecordTotal() {
      // console.log(this.staffTable.total, this.staffTable.filterCount)
      return this.staffTable.total - this.staffTable.filterCount
    },

  },
  watch: {
    toBeTerminateStaffList: function () {
      // console.log("watch")
      this.refreshValidStaffFilterCount();
      // console.log(this.staffTable.filterCount);
    }
  },
  created() {
    this.loadData();
  },
  beforeRouteLeave() {
    this.resetSelectedTerminateList();
    this.closeDrawerAndCleanState();
    this.$store.commit("clearSearchResignObj");
  },
};
</script>
